import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import useAuth from '~common/hooks/use-auth';

const router = createBrowserRouter([
  {
    path: '/',
    element: <h1>After My Death - Dashboard</h1>,
  },
]);

function Router() {
  // Restrict access to this entire app. If in the future it becomes necessary
  // to expose public routes within the dashboard app this hook will need to be
  // called by each protected route instead.
  useAuth();

  return (
    <RouterProvider router={router} />
  );
}

export default Router;
