import { useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';

function useAuth() {
  useEffect(() => {
    const getUser = async () => {
      try {
        await getCurrentUser();
      } catch (error) {
        window.location.replace(import.meta.env.VITE_DOMAIN_AUTH);
      }
    };

    getUser();
  }, []);
}

export default useAuth;
